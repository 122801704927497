  AddStockWriteOffItem
<template>
  <form v-if="warehouse" class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg">

    <template v-if="warehouse.id">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="col-span-1 md:col-span-2">
          <h2 class="text-xl font-bold dark:text-gray-300">Preces</h2>
        </div>

        <template v-for="(item, index) in stockBalanceItems" :key="index">
          <div class="col-span-1 md:col-span-2">
            <SingleStockWriteOffItem :item="item" @item="(value) => setItem(value, index)" @remove-product="removeOrderItem(index)" />
          </div>
        </template>

        <div class="col-span-1">
          <Autocomplete placeholder="Prece" url="/api/fetch_catalog_items" @autocompleteItem="getCatalogItem" :params="{warehouse_id: warehouse.id}" :errors="errors.selectedInventoryItems" :additionalFields="['sku']" :clearOnClick="true" />
        </div>
      </div>
    </template>

    <div class="pt-5">
      <div class="flex justify-end">
        <button @click="hideAddForm" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
          Atcelt
        </button>

        <template v-if="stockBalanceItems.length > 0">
          <template v-if="!loading">
            <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
              Saglabāt
            </button>
          </template>
          <template v-else>
            <Loading />
          </template>
        </template>
      </div>
    </div>

  </form>
</template>

<script>
import { mapGetters } from "vuex";

import Autocomplete from "@/components/Components/Autocomplete";
import Loading from "@/components/Components/Loading";
import Validation from "@/modules/Validation";
import SingleStockWriteOffItem from "@/components/Stock/TehnicalDocuments/TehnicalDocumentsForms/SingleStockWriteOffItem"

export default {
  name: "AddStockWriteOffItem",
  components: {
    Autocomplete,
    Loading,
    SingleStockWriteOffItem,
  },
  props: {
      warehouse: {
          type: Object,
          required: true,
      },
      technicalDocument: {
          required: true,
      },
  },
  data: () => ({
    catalogItem: null,
    stockBalanceItems: [],
    formValidation: null,
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
      warehouses: "warehousesList",
      errors: "errors",
    }),
  },
  watch: {
    catalogItem() {
      if(this.catalogItem) {
        this.addCatalogItemToStockBalanceItemsList()
        this.catalogItem = null
      }
    }
  },
  methods: {
    setItem(value, index) {
      this.stockBalanceItems[index] = value
    },
    setItemErrors() {
      this.formValidation = {}
      this.stockBalanceItems.map((item) => {
        this.formValidation[`quantity-${item.id}`] = {rules: ['required']};
      })
    },
    getItemsValidationValues() {
      this.setItemErrors()
      let form = {}
      this.stockBalanceItems.map((item) => {
        form[`quantity-${item.id}`] = item.quantity
      })

      return form
    },
    clearItems(){
      this.stockBalanceItems = []
    },
    getCatalogItem(value) {
      this.catalogItem = value
    },
    addCatalogItemTostockBalanceItems() {
      this.catalogItem.quantity = null
      this.catalogItem.difference = null

      this.stockBalanceItems.push(this.catalogItem)
    },
    addCatalogItemToStockBalanceItemsList() {
      const componentExists = this.stockBalanceItems.some( component => {
        return component.id === this.catalogItem.id
      })

      if(!componentExists) {
        this.addCatalogItemTostockBalanceItems()
      }
      else this.addStockBalanceItemsQuantity()
    },
    addStockBalanceItemsQuantity() {
      this.stockBalanceItems.forEach( component => {
        if (component.id === this.catalogItem.id) {
          component.quantity = ++component.quantity
        }
      })
    },
    removeOrderItem(index) {
      this.stockBalanceItems.splice(index, 1);
    },
    formatstockBalanceItems() {
      return this.stockBalanceItems.map( orderItem => {
        return {
          catalog_item_id: orderItem.id,
          quantity: orderItem.quantity,
          notes: orderItem.notes ? orderItem.notes : ''
        }
      })
    },
    hideAddForm() {
      this.$store.dispatch('removeAllFormsForDisplay')
    },
    submit() {
      this.$Progress.start();
      const form = this.getItemsValidationValues()
      if (Validation(this.formValidation, form)) {
        this.$store.dispatch('addWriteOffItems', {
            id: this.technicalDocument.documentable.id,
            document_id: this.technicalDocument.id,
            data: {
                items : this.formatstockBalanceItems(),
            }
        })
      } else this.$Progress.fail();
    },
  },
};
</script>

<style>

</style>